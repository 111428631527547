import { IAppNames, IAllowedRoles, IInitialValues } from './interfaces'

export * from './labels'
export * from './placeholders'
export * from './titles'

export const appNames: IAppNames = {
 insights: 'insights',
 legacy: 'legacy_insights'
}

export const fieldsInitialValues = {
    recipients: '',
    subject: '',
    message: '',
    body: '',
    dashBoardItems: []
} as IInitialValues

export const allowedRoles: IAllowedRoles = {
    insights: 'PistilInsights',
    legacy: 'PistilLegacy',
    legacy_insights: 'PistilLegacy'
}

export const filtersType = {
    Country: "COUNTRY",
    State: "STATE"
}

const insightsHosts = [
    'staging.insights.pistildata.com',
    'dev.insights.pistildata.com'
]
const reactAppEnv = process.env["REACT_APP_ENVIRONMENT"]
const currentHost = window.location.host

export const isInsights = reactAppEnv === 'prod-insights' || insightsHosts.includes(currentHost)

export const premiumSub = 'PREMIUM'

export const filtersLabels = ['COUNTY','PISTIL_RETAILER_ID','CONSUMER_AGE','CONSUMER_GENDER'] 

export const IssuesCodes: Record<string, string> = {
    'NoCompany': 'There was an error when setting up your account',
    'NoSigmaUserSetting': 'There was an error when setting up your account',
    'NoInsightsRole': 'There was an error when setting up your account',
    'InactiveUser': 'There was an error when setting up your account',
    'InactiveCredentials': 'There was an error when setting up your account',
    'CompanyInactive': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'NoSigmaTeam':'There was an error when setting your company account. Please contact support@pistildata.com',
    'CompanyNoInsightsSubscription': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'CompanyWithoutSubscriptions': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'CompanyNoActiveTrialSubscriptions': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'SubscriptionNoSettings': 'There was an error when setting your company account. Please contact support@pistildata.com',
    'SubscriptionNoActiveSetting': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'SettingsNoCategoriesJson': 'There was an error when setting your company account. Please contact support@pistildata.com',
    'InsightsSubscriptionInactive': 'Your Pistil Data subscription has been deactivated. If this is a mistake, please contact support@pistildata.com. If you would like to sign up again, please contact sales@pistildata.com.',
    'InsightsSubscriptionNoTier': 'There was an error when setting up your insights subscription. Please contact support@pistildata.com',
    'InsightsUserLacksRole': 'There was an error when setting up your account. Please contact support@pistildata.com ',
    'InsightsUserLacksStateAccess': 'There was an error when setting up your account, it lacks access to the Insights subscription. Please contact support@pistildata.com'

}

export const fileTypes = [
    {
        label: 'CSV'
    },
    {
        label: 'XLSX'
    }
]

export const defaultSource = {workbookElementGuid: '', elementDisplayName: 'Select source', workboookGuid: '', workbookDisplayName: ''}